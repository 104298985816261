import { ROLE } from '@/constants/enum';
import { useModel } from '@umijs/max';
import { Spin } from 'antd';

/** 获取当前角色 - 默认为 SEEKER; 如果为 undefined 则数据加载中 */
export function useRole() {
  const { initialState, loading } = useModel('@@initialState');
  if (loading || !initialState) {
    return undefined;
  }
  return (initialState?.role || ROLE.SEEKER) as ROLE;
}

export const SwitchRole: React.FC<{
  seeker?: () => React.ReactNode;
  interview?: () => React.ReactNode;
}> = ({ seeker, interview }) => {
  const role = useRole();
  if (!role) {
    return <Spin></Spin>;
  }
  return <>{role === ROLE.SEEKER ? seeker?.() : interview?.()}</>;
};
