// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { HelmetProvider } from '/root/codingci/tools/jenkins_home/workspace/4395925-cci-46764225-723831/node_modules/.pnpm/@umijs+renderer-react@4.3.24_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@umijs/renderer-react';
import { context } from './helmetContext';

export const innerProvider = (container) => {
  return React.createElement(HelmetProvider, { context }, container);
}
