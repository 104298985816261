/**
 * 埋点方案
 * - 业务埋点 - umami
 * - 页面初始化埋点 - 仅 initial state 过程结束时， 可选埋点
 */

export const track: typeof window.umami.track = (...args) => {
  if (typeof window.umami === 'undefined') {
    return;
  }
  return window.umami.track(...args);
};
