// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_2 from '/root/codingci/tools/jenkins_home/workspace/4395925-cci-46764225-723831/apps/www/src/pages/Dashboard/Profile/model';
import model_3 from '/root/codingci/tools/jenkins_home/workspace/4395925-cci-46764225-723831/apps/www/src/pages/model';
import model_4 from '/root/codingci/tools/jenkins_home/workspace/4395925-cci-46764225-723831/apps/www/src/.umi-production/plugin-initialState/@@initialState';
import model_1 from '/root/codingci/tools/jenkins_home/workspace/4395925-cci-46764225-723831/apps/www/src/models/global';

export const models = {
model_2: { namespace: 'Dashboard.Profile.model', model: model_2 },
model_3: { namespace: 'model', model: model_3 },
model_4: { namespace: '@@initialState', model: model_4 },
model_1: { namespace: 'global', model: model_1 },
} as const
