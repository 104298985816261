import { queryStorage } from '@/utils/url-store';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const useIndexUrlStore = create<{
  s?: string;
  setSearch(str?: string): void;
}>()(
  persist(
    (set) => {
      const searchParams = new URLSearchParams(location.search);
      return {
        s: searchParams.get('s') ?? undefined,
        setSearch: (str: string) => set({ s: str }),
      };
    },
    {
      name: 'index', // unique name
      storage: createJSONStorage(() => queryStorage),
    },
  ),
);

export default function () {
  return {};
}
