import { ROLE } from '@/constants/enum';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type TypeGlobalState = {
  snap?: {
    uid?: string;
    telephone?: string;
    role?: ROLE;
  };
  setSnap(val: Partial<TypeGlobalState['snap']>): void;
};

/**
 * 暂存一份全局配置, 用于未跳转登录时 - 获取上次要重新登录的角色
 *
 */
export const useGlobalStore = create<TypeGlobalState>()(
  persist(
    (set, get) => {
      return {
        snap: {
          uid: undefined,
          telephone: undefined,
          role: undefined,
        },
        setSnap(val) {
          set({ snap: { ...get().snap, ...val } });
        },
      };
    },
    {
      name: '_ql_global_',
      partialize(state) {
        return {
          snap: state.snap,
        };
      },
    },
  ),
);
