import { Button, ButtonProps } from 'antd';
import clsx from 'clsx';

const STORE_KEY = 'rel';
const IGNORE_PATH = ['/'];
const IGNORE_PATH_PREFIX = ['/user'];
/**
 * 添加 rel 参数
 */
export function generateRelUrl(href = '/') {
  // 当前 url 上的 rel 参数
  const currentRel = new URLSearchParams(location.search).get(STORE_KEY);
  // 如果已有, 不覆盖, 直接返回原路径
  if (currentRel) {
    return href;
  }
  const pathname = location.pathname;
  const search = location.search;
  // 不追加当前页面到 rel
  const ignoreCurrentPathname =
    // 没有 search 时 - 匹配 IGNORE_PATH
    (!search && IGNORE_PATH.includes(pathname)) ||
    IGNORE_PATH_PREFIX.some((prefix) => pathname.startsWith(prefix));
  if (ignoreCurrentPathname) {
    return href;
  }

  const nextRel = currentRel || `${location.pathname}${location.search}`;

  // 传入的 跳转 链接
  const [hrefPathname, searchStr] = href.split('?');
  const nextSearchParams = new URLSearchParams(searchStr);

  nextSearchParams.set(STORE_KEY, nextRel);

  return [hrefPathname, nextSearchParams.toString()].filter(Boolean).join('?');
}

/**
 * 从 url 参数读取 rel url, 支持修改参数
 * @param fallback
 * @param moreParams
 * @returns
 */
export function getRelUrl(fallback = '/', moreParams?: Record<string, string>) {
  const rel = new URLSearchParams(location.search).get('rel');
  let nextUrl = rel || fallback || '';
  if (moreParams) {
    const [p, s] = nextUrl.split('?');
    const params = new URLSearchParams(s);
    Object.entries(moreParams).forEach(([key, value]) => {
      if (value) {
        params.set(key, value);
      } else {
        params.delete(key);
      }
    });
    nextUrl = [p, params.toString()].filter(Boolean).join('?');
  }
  return nextUrl;
}

/**
 * Button#link
 * 点击时 - 将当前页面的 pathname?search 带到新的 url 的 rel 参数上; 如果已有 rel, 则不覆盖
 * @param param0
 * @returns
 */
export const RelLink: React.FC<Omit<ButtonProps, 'type'>> = ({
  href,
  onClick,
  children,
  ...props
}) => {
  return (
    <Button
      type="link"
      {...props}
      href={href}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.(e);

        const nextHref = generateRelUrl(href);

        return window.open(nextHref, props.target);
      }}
    >
      {children}
    </Button>
  );
};

export const RelAnchor: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = ({ href, onClick, children, target, className, ...props }) => {
  return (
    <a
      href={href}
      target={target}
      className={clsx(
        'decoration-none inline-block',
        className || 'text-sm px-2 py-1',
      )}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.(e);

        const nextHref = generateRelUrl(href);

        return window.open(nextHref, target);
      }}
    >
      {children}
    </a>
  );
};
