import clsx from 'clsx';

export const LoadingText: React.FC<{
  className?: string;
  loading?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}> = ({ children, loading, className, ...props }) => {
  return (
    <div
      className={clsx(
        className,
        `relative `,
        `before:block before:content-empty before:absolute before:w-[100%] before:h-[100%] before:rounded-[4px]`,
        loading &&
          `before:bg-black/20 before:animate-name-pulse-alt before:animate-duration-1000 before:animate-iteration-infinite`,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
