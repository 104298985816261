import { ICP } from '@urzhi/www/src/constants';
import { Space } from 'antd';
export const DefaultFooter: React.FC = () => {
  return (
    <div
      className={
        'bg-white border-0 border-t-[1px] border-#e8e8e8 border-solid pt-[20px] pb-[64px]'
      }
    >
      <div
        className={
          'px-4 mx-auto max-w-[var(--page-w)] text-[#999] text-xs grid gap-2'
        }
        style={{ gridTemplateColumns: '1fr auto' }}
      >
        <div className={'grid grid-cols-1 gap-2'}>
          <Space wrap split={<em className={'text-gray'}>{'|'}</em>}>
            {/* <span>
              <a
                className="text-gray hover:text-dark"
                target="_blank"
                rel="noreferrer"
                href={'/document/about'}
                data-umami-event="footer_about"
              >
                {'关于我们'}
              </a>
            </span> */}
            <span>
              <a
                className="text-gray hover:text-dark"
                target="_blank"
                rel="noreferrer"
                href={'/document/privacy-terms'}
                data-umami-event="footer_terms"
              >
                {'隐私条款'}
              </a>
            </span>
            <span>
              <a
                className="text-gray hover:text-dark"
                target="_blank"
                rel="noreferrer"
                href={'/document/user-agreement'}
                data-umami-event="footer_user-agree"
              >
                {'用户协议'}
              </a>
            </span>
            {/* <span>
              <a
                className="text-gray hover:text-dark"
                target="_blank"
                rel="noreferrer"
                href={'/document/contact'}
                data-umami-event="footer_contact-agree"
              >
                {'联系我们'}
              </a>
            </span> */}
            {/* <span>
              <a
                className="text-gray hover:text-dark"
                target="_blank"
                rel="noreferrer"
                href={'/document/help'}
                data-umami-event="footer_help"
              >
                {'帮助'}
              </a>
            </span> */}
          </Space>
          <Space split={<em className={'text-gray'}>{'|'}</em>}>
            <span>
              网站备案/许可证号:{' '}
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
                className="text-neutral"
                data-umami-event="footer_icp"
              >
                {ICP}
              </a>
            </span>
            {/* <span>电子营业执照/人力资源服务许可证</span> */}
          </Space>
          <span>Copyright ©长沙青豆科技有限公司版权所有</span>
        </div>
        <div className={'flex gap-2 min-h-[90px]'}>
          {/* 二维码图片 */}
          {/* <div
            className="border border-dashed border-gray"
            style={{ width: 90, height: 90 }}
          ></div> */}
          {/* <div
            className="border border-dashed border-gray"
            data-umami-event="footer_qr"
            style={{ width: 90, height: 90 }}
          ></div> */}
        </div>
      </div>
    </div>
  );
};
