import { getSession } from '@urzhi/www/src/services/v1/session';
import ExpiryMap from 'expiry-map';
import pMemoize from 'p-memoize';
import { CUSTOM_REQ_OPT } from 'typings';
import { queryInterviewer } from './transform';

// Cached values expire after 10 seconds
const cache = new ExpiryMap(10000);

export const getSessionCached = pMemoize(getSession, {
  cache,
  cacheKey: () => '_get_session_',
}) as (payload: CUSTOM_REQ_OPT) => ReturnType<typeof getSession>;

// queryInterviewerProfileByUid
export const getInterviewer = pMemoize(queryInterviewer, {
  cache,
  cacheKey: ([payload]) => `_detail_i_${payload.uid}`,
}) as (
  payload: CUSTOM_REQ_OPT & Parameters<typeof queryInterviewer>[0],
) => ReturnType<typeof queryInterviewer>;
