// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** hotInterviewer POST /recommend/hotInterviewer */
export async function hot(options?: { [key: string]: any }) {
  return request<API.JsonResultListRecommendInterviewerVo>(
    `/api/recommend/hotInterviewer`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

/** recommend interviewer POST /recommend/Interviewer */
export async function recommend(
  body: API.RecommendReq,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultListRecommendInterviewerVo>(
    `/api/recommend/Interviewer`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** search GET /recommend/search */
export async function search(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.searchParams,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultListRecommendInterviewerVo>(
    `/api/recommend/search`,
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}
