const meta = {
  /** 匹配此条件 - 则不请求登录信息 */
  noAuth: ['/user', '/document'],
  /** 匹配此条件 - 则未登录进行跳转 */
  shouldLogin: ['/dashboard'],
};

/**
 * 判别路径是否需要登录-是否未登录跳转
 * @param pathname
 * @returns
 */
export function judgePath(pathname = location.pathname) {
  return {
    noAuth: meta.noAuth.some((prefix) => {
      return pathname.startsWith(prefix);
    }),
    shouldLogin: meta.shouldLogin.some((prefix) => {
      return pathname.startsWith(prefix);
    }),
  };
}
