// 对一些请求进行预处理

import { BookingStatus, OrderStatus, PositionType } from '@/constants/enum';
import { fillId, safeParse } from '@/utils/string';
import {
  myProfile1,
  queryInterviewerProfileByUid,
} from './v1/gerenxinximianshiguan';
import { recommend, search } from './v1/mianshiguantuijian';
import { orderDetail } from './v1/mianshiliucheng';
import { appraiseDetail, appraiseList, list } from './v1/wodemianshi';

export type TypeAcceptPositionItem = {
  _id?: string;
  position?: string[];
  price?: number;
  /** 自描述职级 */
  rank?: string;
  type?: PositionType;
};

function isValidPositionItem(item: TypeAcceptPositionItem) {
  return typeof item.price === 'number' && Array.isArray(item.position);
}

export function fillPositionsInterviewVo<
  T extends {
    specializedPosition?: string;
  },
>(
  vo?: T,
): Omit<T, 'specializedPosition'> & {
  positions: TypeAcceptPositionItem[];
} {
  const { specializedPosition, ...others } = vo || ({} as T);
  return {
    ...others,
    positions: fillId(
      safeParse<TypeAcceptPositionItem[]>(specializedPosition, []).filter(
        isValidPositionItem,
      ),
    ),
  };
}

export type FillInterviewer<
  T extends {
    specializedPosition?: string;
  },
> = Omit<T, 'specializedPosition'> & {
  positions?: TypeAcceptPositionItem[];
};

export type InterviewerProfile = FillInterviewer<API.InterviewerProfileVo>;

export async function myProfileInterviewer(): Promise<InterviewerProfile> {
  const data = (await myProfile1()).data;
  return fillPositionsInterviewVo(data);
}

export async function queryInterviewer(
  payload: API.queryInterviewerProfileByUidParams,
): Promise<InterviewerProfile> {
  const data = (await queryInterviewerProfileByUid(payload)).data;
  return fillPositionsInterviewVo(data);
}

export async function orderList(body: API.InterviewListRequest) {
  const { data } = await list(body);
  return {
    ...data,
    list: data?.dataSet?.map(({ interviewerProfileVo, ...item }) => ({
      ...item,
      // 面试官信息 - 将职位反转
      interviewerProfileVo: fillPositionsInterviewVo(interviewerProfileVo),
    })),
  };
}

export async function recommendList(body: API.RecommendReq, options?: any) {
  const { data } = await recommend(body, options);
  return data?.map(fillPositionsInterviewVo);
}

export async function recommendListWild(body: API.searchParams, options?: any) {
  const { data } = await search(body, options);
  return data?.map(fillPositionsInterviewVo);
}

/**
 * 处理 statusCode 字段 - bookingStatus 已拒绝时 - 覆盖到 orderStatus
 * @param payload
 * @returns
 */
export const orderDetailDealStatus: typeof orderDetail = async function (
  payload,
) {
  const result = await orderDetail(payload);
  if (result.data) {
    if (result.data.bookingStatus === BookingStatus.已拒绝) {
      result.data.statusCode = OrderStatus.已拒绝;
    }
  }
  return result;
};

// 评价列表 - 修正数据字段
export const appraiseListFixed: typeof appraiseList = async (payload) => {
  //
  const result = await appraiseList(payload);
  result.data?.pageResult?.dataSet?.forEach((item) => {
    item.interviewAssessment =
      item.interviewAssessment || (item as any).InterviewAssessment;
    item.seekerName = item.seekerName || (item as any).SeekerName;
  });
  return result;
};

export const appraiseDetailFixed: typeof appraiseDetail = async (payload) => {
  const result = await appraiseDetail(payload);
  if (result?.data) {
    result.data.interviewAssessment =
      result.data.interviewAssessment ||
      (result as any).data.InterviewAssessment;
    result.data.seekerName =
      result.data.seekerName || (result as any).data.SeekerName;
  }
  return result;
};
