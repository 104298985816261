import { StateStorage } from 'zustand/middleware';

const getUrlSearch = () => {
  return window.location.search.slice(1);
};

export const queryStorage: StateStorage = {
  getItem: (storageKey) => {
    const searchParams = new URLSearchParams(getUrlSearch());
    const state = Object.fromEntries(
      [...searchParams.entries()]
        .filter((item) => {
          return item[0].startsWith(`${storageKey}_`);
        })
        .map(([key, value]) => [key.replace(`${storageKey}_`, ''), value]),
    );
    return JSON.stringify({
      state,
      version: 0,
    });
  },
  setItem: (storageKey, newValue): void => {
    const liteValue = JSON.parse(newValue).state as Record<string, any>;
    const searchParams = new URLSearchParams(getUrlSearch());

    Object.entries(liteValue).forEach(([key, value]) => {
      if (value || value === 0) {
        searchParams.set(`${storageKey}_${key}`, value);
      } else {
        searchParams.delete(`${storageKey}_${key}`);
      }
    });
    const nextSearchStr = searchParams.toString();
    window.history.replaceState(
      null,
      '',
      `${location.pathname}${nextSearchStr ? '?' : ''}${nextSearchStr}`,
    );
  },
  removeItem: (storageKey): void => {
    const searchParams = new URLSearchParams(getUrlSearch());
    [...searchParams.keys()].forEach((key) => {
      if (key.startsWith(`${storageKey}_`)) {
        searchParams.delete(key);
      }
    });
    const nextSearchStr = searchParams.toString();
    window.history.replaceState(
      null,
      '',
      `${location.pathname}${nextSearchStr ? '?' : ''}${nextSearchStr}`,
    );
  },
};
