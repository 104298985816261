import { useLocation, useNavigate } from '@umijs/max';
import clsx from 'clsx';
import React from 'react';

export const TopNavItem: React.FC<
  {
    to: string;
    children?: React.ReactNode;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
> = ({ to, children, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const isActive = to === pathname;
  return (
    <div
      className={clsx(
        'rounded-lg py-2 px-2 min-w-[100px] cursor-pointer font-[500] grid place-items-center',

        'hover:text-[#cf6044]/80',
        isActive ? 'text-[#cf6044]/100' : 'text-[#cf6044]/90',
        'relative',
        'transition-colors',
        'before:absolute before:content-empty before:w-[100px] before:h-1 before:bg-[#cf6044] before:left-0 before:top-[100%] before:transform-origin-[50%_50%] before:transition-transform',
        'before:scale-y-100 before:scale-z-100',
        isActive
          ? 'hover:before:scale-x-100 before:scale-x-90'
          : 'hover:before:scale-x-100 before:scale-x-0',
      )}
      style={{
        transform: isActive ? '' : '',
      }}
      onClick={() => {
        navigate(to);
      }}
      {...props}
    >
      {children}
    </div>
  );
};
