import AnimatedGradientText from '@/components/magicui/animated-gradient-text';
import { cn } from '@/lib/utils';
import { useNavigate } from '@umijs/max';
import logoUrl from '@urzhi/www/src/assets/logo.png';
import clsx from 'clsx';
import React from 'react';
import { TopNavItem } from './TopNav';

export const DefaultHeader: React.FC<{
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  bottom?: React.ReactNode;
  gridTemplateColumns?: string;
  sticky?: boolean;
}> = ({ left, center, right, gridTemplateColumns, sticky, bottom }) => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx(
        'bg-white border-0 border-b-[1px] border-solid border-#e8e8e8',
        sticky === false ? '' : 'sticky top-0 z-[999]',
      )}
    >
      <div
        className={
          'pl-1 pr-4 mx-auto max-w-[var(--page-w)] grid grid-rows-1 gap-4 items-center'
        }
        style={{
          gridTemplateColumns: gridTemplateColumns || 'auto 1fr auto auto',
        }}
      >
        <div>
          <img src={logoUrl} width={166} height={60} />
        </div>
        {/* 导航 */}
        <div className="flex items-center gap-2 text-sm h-full">
          <TopNavItem to="/">{'首页'}</TopNavItem>
          {left}
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate('/ai-resume');
            }}
          >
            <AnimatedGradientText border>
              <span
                className={cn(
                  `inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`,
                )}
              >
                {'✨ AI 简历分析'}
              </span>
            </AnimatedGradientText>
          </div>
        </div>
        <div>{center}</div>
        <div className="flex gap-2 items-center">{right}</div>
        {bottom}
      </div>
    </div>
  );
};
