// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** change my role GET /changeRole */
export async function changeRole(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.changeRoleParams,
  options?: { [key: string]: any },
) {
  return request<boolean>(`/api/changeRole`, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** get my session GET /session */
export async function getSession(options?: { [key: string]: any }) {
  return request<API.Session>(`/api/session`, {
    method: 'GET',
    ...(options || {}),
  });
}
