// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** get my profile GET /interviewer/myProfile */
export async function myProfile1(options?: { [key: string]: any }) {
  return request<API.JsonResultInterviewerProfileVo>(
    `/api/interviewer/myProfile`,
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** get my profile GET /interviewer/queryInterviewerProfileByUid */
export async function queryInterviewerProfileByUid(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.queryInterviewerProfileByUidParams,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultInterviewerProfileVo>(
    `/api/interviewer/queryInterviewerProfileByUid`,
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** save profile POST /interviewer/saveProfile */
export async function saveProfile1(
  body: API.InterviewerProfileVo,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultBoolean>(`/api/interviewer/saveProfile`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** save Schedule POST /interviewer/saveSchedule */
export async function saveSchedule(
  body: API.ScheduleVo[],
  options?: { [key: string]: any },
) {
  return request<API.JsonResultBoolean>(`/api/interviewer/saveSchedule`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** save work experience POST /interviewer/saveWorkExperience */
export async function saveWorkExperience(
  body: API.WorkExperienceVo[],
  options?: { [key: string]: any },
) {
  return request<API.JsonResultBoolean>(`/api/interviewer/saveWorkExperience`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
