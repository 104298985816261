// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** generate captcha code GET /usercenter/generateCaptchaCode */
export async function generateCaptchaCode(options?: { [key: string]: any }) {
  return request<any>(`/api/usercenter/generateCaptchaCode`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** login by Password POST /usercenter/loginByPassword */
export async function loginByPassword(
  body: API.LoginReq,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultBoolean>(`/api/usercenter/loginByPassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** login by VerificationCode POST /usercenter/loginByVerificationCode */
export async function loginByVerificationCode(
  body: API.LoginByVerifyCodeReq,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultBoolean>(
    `/api/usercenter/loginByVerificationCode`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** logout GET /usercenter/logout */
export async function logout(options?: { [key: string]: any }) {
  return request<API.JsonResultString>(`/api/usercenter/logout`, {
    method: 'GET',
    ...(options || {}),
  });
}

/** send VerificationCode GET /usercenter/sendVerificationCode */
export async function sendVerificationCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.sendVerificationCodeParams,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultString>(`/api/usercenter/sendVerificationCode`, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
