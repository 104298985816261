import { useQuery } from '@tanstack/react-query';
import { getSessionCached } from './../services/common';
// 全局共享数据示例
import { DEFAULT_NAME } from '@/constants';
import { useModel } from '@umijs/max';
import { useMemo, useState } from 'react';

export const globalRef: API.Session = {};

const useUser = () => {
  const { initialState } = useModel('@@initialState');
  const { data } = useQuery({
    queryKey: ['session', initialState?.uid],
    enabled: Boolean(initialState?.uid),
    queryFn() {
      return getSessionCached({
        IS_SESSION: true,
      });
    },
    refetchInterval: 15 * 1000,
    refetchIntervalInBackground: false,
    retry: false,
  });

  useMemo(() => {
    Object.assign(globalRef, data);
  }, [data]);

  const [name, setName] = useState<string>(DEFAULT_NAME);
  return {
    name,
    setName,
  };
};

export default useUser;
