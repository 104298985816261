import { ROLE } from './constants/enum';

export default (initialState: API.Session) => {
  // 在这里按照初始化数据定义项目中的权限，统一管理
  // 参考文档 https://umijs.org/docs/max/access
  const canSeeAdmin = !!(
    initialState && initialState.name !== 'dontHaveAccess'
  );
  const canSeeInterviewers = initialState && initialState.role === ROLE.SEEKER;
  const isInterviewerFilter = () =>
    initialState && initialState.role === ROLE.INTERVIEWER;
  const isInterviewer = isInterviewerFilter();
  const isSeekerFilter = () =>
    initialState && initialState.role === ROLE.SEEKER;
  const isSeeker = isSeekerFilter();
  const canAccessIncome = isInterviewer;
  return {
    canSeeAdmin,
    canSeeInterviewers,
    isInterviewerFilter,
    isInterviewer,
    isSeekerFilter,
    isSeeker,
    canAccessIncome,
  };
};
