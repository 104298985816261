import { parse } from 'best-effort-json-parser';

export const nano = () => Math.random().toString(36).slice(2, 6);

export function leftPad0(input: string | number, length: number): string {
  return `${'0'.repeat(length)}${input}`.slice(-length);
}

export function safeParse<T = any>(
  _input?: string | number | null,
  defaultValue?: T,
): T {
  let input = _input?.toString()?.trim();
  if (input && typeof input === 'string') {
    if (input.startsWith('```')) {
      input = input.replace(/^```json\n/, '').replace(/```$/, '');
    }
    try {
      if (input.startsWith('{')) {
        return parse(input) as T;
      }
      return JSON.parse(input) as T;
    } catch (error: any) {
      console.groupCollapsed('[safe parse]', error?.message);
      console.debug(error);
      console.debug(input);
      console.groupEnd();
    }
  }
  return defaultValue as T;
}

export function fillId<T extends { _id?: string; [k: string]: any }>(
  items: T[],
) {
  if (items.some((item) => !item['_id'])) {
    return items.map((item) => {
      if (!item._id) {
        return { ...item, _id: nano() };
      }
      return item;
    });
  }
  return items;
}
