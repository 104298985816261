import { LoadingBtn } from '@/components/Button/LoadingBtn';
import { generateRelUrl } from '@/components/Button/RelLink';
import { useGlobalStore } from '@/models/store';
import { CloseCircleFilled, ReloadOutlined } from '@ant-design/icons';
import { sendVerificationCode } from '@urzhi/www/src/services/v1/yonghudenglu';
import { registerOrLogin } from '@urzhi/www/src/services/v1/yonghuzhuce';
import { message } from 'antd';

const RELOGIN_TIP_KEY = '_tip_relogin_';

export const RELOGIN_STATE = {
  hasLogined: 0,
  opened: false,
};

/**
 *
 * @param isINIT
 */
export function reLogin(isINIT = false) {
  // 跳转登录页面
  const snappedUser = { ...useGlobalStore.getState().snap };
  const lastRole = snappedUser.role;
  const lastTelephone = isINIT ? '' : snappedUser.telephone;
  location.href = generateRelUrl(
    `/user/signin?${new URLSearchParams({
      role: lastRole || '',
      telephone: lastTelephone || '',
    }).toString()}`,
  );
}

export async function reLoginDev() {
  const snappedUser = { ...useGlobalStore.getState().snap };
  if (!snappedUser.telephone) {
    return reLogin();
  }

  const result = await sendVerificationCode({
    telephone: snappedUser.telephone,
  });
  if (!result.data) {
    return reLogin();
  }
  await registerOrLogin({
    telephone: snappedUser.telephone,
    verificationCode: result.data,
    role: snappedUser.role,
  });
  return true;
}

// 多次调用 - 仅显示一个

const Relogin: React.FC<{ msg?: string }> = ({ msg }) => {
  return (
    <div className="min-w-[240px] flex items-center text-[#ff4d4f]">
      <CloseCircleFilled />
      <div className="ml-1 mr-auto">{msg}</div>
      <LoadingBtn
        icon={<ReloadOutlined />}
        type="text"
        danger
        size="small"
        onClick={async () => {
          if (process.env.NODE_ENV === 'development') {
            const done = await reLoginDev();
            if (done) {
              message.destroy(RELOGIN_TIP_KEY);
            }
            return;
          } else {
            return reLogin();
          }
        }}
      >
        重新登录
      </LoadingBtn>
    </div>
  );
};

export function toastReLogin(msg?: string) {
  RELOGIN_STATE.opened = true;
  message.open({
    // type: 'error',
    icon: null,
    content: <Relogin msg={msg} />,
    duration: 0,
    key: RELOGIN_TIP_KEY,
    onClose() {
      RELOGIN_STATE.opened = false;
    },
  });
}
