// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** save appraise POST /appraise/save */
export async function save(
  body: API.AppraiseEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultAppraiseEditResponse>(`/api/appraise/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** submit appraise POST /appraise/submit */
export async function submit(
  body: API.AppraiseEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultAppraiseEditResponse>(`/api/appraise/submit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** appraise template delete POST /appraise/templateDelete */
export async function templateDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.templateDeleteParams,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultString>(`/api/appraise/templateDelete`, {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** appraise template detail GET /appraise/templateDetail */
export async function templateDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.templateDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultTemplateDetailResponse>(
    `/api/appraise/templateDetail`,
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** appraise template list POST /appraise/templateList */
export async function templateList(options?: { [key: string]: any }) {
  return request<API.JsonResultTemplateListResponse>(
    `/api/appraise/templateList`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}

/** appraise template save POST /appraise/templateSave */
export async function templateSave(
  body: API.TemplateSaveRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultString>(`/api/appraise/templateSave`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** view appraise GET /appraise/veiw */
export async function view(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.viewParams,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultAppraiseViewResponse>(`/api/appraise/veiw`, {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** seeker appraise POST /myinterview/appraise */
export async function appraiseInterview(
  body: API.InterviewAppraiseRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultString>(`/api/myinterview/appraise`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** seeker appraise detail GET /myinterview/appraiseDetail */
export async function appraiseDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.appraiseDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultAppraiseDetailVo>(
    `/api/myinterview/appraiseDetail`,
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** seeker appraise list POST /myinterview/appraiseList */
export async function appraiseList(
  body: API.AppraiseListRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultAppraiseListVo>(
    `/api/myinterview/appraiseList`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** cancel interview POST /myinterview/cancel */
export async function cancelInterview(
  body: API.InterviewCancelRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultString>(`/api/myinterview/cancel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** edit interview info POST /myinterview/editTime */
export async function editTime(
  body: API.InterviewEditTimeRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultString>(`/api/myinterview/editTime`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** interviewer info POST /myinterview/interviewerInfo */
export async function interviewerInfo(
  body: API.InterviewDetailInfoRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultInterviewerInfoVo>(
    `/api/myinterview/interviewerInfo`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** interview info POST /myinterview/interviewInfo */
export async function interviewInfo(
  body: API.InterviewDetailInfoRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultInterviewInfoVo>(
    `/api/myinterview/interviewInfo`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** list POST /myinterview/list */
export async function list(
  body: API.InterviewListRequest,
  options?: { [key: string]: any },
) {
  return request<API.JsonResultPageResultInterviewListVo>(
    `/api/myinterview/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}

/** status enums POST /myinterview/statusEnum */
export async function statusEnum(options?: { [key: string]: any }) {
  return request<API.JsonResultInterViewStatusEnumsVos>(
    `/api/myinterview/statusEnum`,
    {
      method: 'POST',
      ...(options || {}),
    },
  );
}
