export const USER_KEY = '_ql_user_';

export function getSnappedUserInfo() {
  const raw = localStorage.getItem(USER_KEY);
  try {
    if (raw) {
      const parsed = JSON.parse(raw) as API.UserInfoVo;
      return {
        uid: parsed.uid,
        name: parsed.nickName,
        avatarUrl: parsed.avatarUrl,
      } satisfies API.Session;
    }
  } catch (error) {
    return undefined;
  }
}

export function setSnappedUserInfo<T = API.UserInfoVo>(payload: T | undefined) {
  if (!payload) {
    localStorage.removeItem(USER_KEY);
  } else {
    localStorage.setItem(USER_KEY, JSON.stringify(payload));
  }
}
