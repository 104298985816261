export const ICP = '湘ICP备2024042930号-1';

export const DEFAULT_NAME = 'Qianlong Seer';

export const SHOW_CAPTCHA =
  process.env.NODE_ENV === 'development' || location.search.includes('_=debug');

export const FAKE_LOGIN = location.search.includes('fake_login=1');
export const LOGIN_FLAG = '_login_';
export const FAKE_LOGIN_FLAG = '_login_faked_';

export const ENABLE_AUTO_REDIRECT = !location.search.includes('_=debug');

export const ENABLE_QINGQUE = location.search.includes('_qingque=debug');

export const ENABLE_WALLET_DEBUG = location.search.includes('_wallet=debug');
export const IS_PRD =
  location.host.endsWith('urzhi.com') && !location.host.includes('test');

// 微信内置浏览器 UA
// Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36 NetType/WIFI MicroMessenger/6.8.0(0x16080000) MacWechat/3.8.6(0x13080612) XWEB/1156 Flue
export const IS_IN_WEIXIN_BROWSER = /(micromessenger|wechat)/i.test(
  navigator.userAgent.toLowerCase(),
);
