import { useEffect, useRef, useState } from 'react';

/** sleep n ms */
export const wait = (ms = 2) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

/** 最少 n ms 完成此 promisee*/
export function minAsync<T = any>(
  fn: Promise<T> | ((...args: any[]) => Promise<T>),
  ms = 420,
) {
  return Promise.all([wait(ms), typeof fn === 'function' ? fn() : fn]).then(
    (chain) => chain[1],
  );
}

/** 至少 n 秒的 loading */
export function useLeastLoading(bool: boolean, least = 400) {
  const [value, setValue] = useState(bool);
  const stamp = useRef(0);
  const timer = useRef<any>();
  useEffect(() => {
    if (value === bool) {
      return;
    }
    clearTimeout(timer.current);
    const delta = Date.now() - stamp.current;
    stamp.current = Date.now();

    const remain = least - delta;
    if (bool === true || remain < 0) {
      setValue(bool);
    } else {
      timer.current = setTimeout(() => {
        setValue(bool);
      }, remain);
    }
  }, [bool]);
  return value;
}
